@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?y463lq');
  src:  url('fonts/icomoon.eot?y463lq#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y463lq') format('truetype'),
    url('fonts/icomoon.woff?y463lq') format('woff'),
    url('fonts/icomoon.svg?y463lq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.material-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-currency_exchange:before {
  content: "\e90b";
}
.icon-event_note:before {
  content: "\e90c";
}
.icon-edit_outline:before {
  content: "\e909";
}
.icon-shipping-icon:before {
  content: "\e908";
}
.icon-two_arrows_up_circle:before {
  content: "\e907";
}
.icon-dbs:before {
  content: "\e906";
}
.icon-blood:before {
  content: "\e904";
}
.icon-urine:before {
  content: "\e905";
}
.icon-content_fill:before {
  content: "\eea4";
}
.icon-paid:before {
  content: "\eea3";
}
.icon-account_2_outlined:before {
  content: "\eea0";
}
.icon-account_2_filled:before {
  content: "\eea1";
}
.icon-account_1_filled:before {
  content: "\eea2";
}
.icon-account_1_outlined:before {
  content: "\ee9f";
}
.icon-push_pin_outlined:before {
  content: "\eeb1";
}
.icon-wada:before {
  content: "\ee9e";
}
.icon-arrow_up_circle:before {
  content: "\ee9d";
}
.icon-cancel_circle:before {
  content: "\ee97";
}
.icon-edit_circle:before {
  content: "\ee98";
}
.icon-empty_circle:before {
  content: "\ee99";
}
.icon-vertical_line_circle:before {
  content: "\ee9a";
}
.icon-checkmark_circle:before {
  content: "\ee9b";
}
.icon-person_circle:before {
  content: "\ee9c";
}
.icon-content_check:before {
  content: "\ead8";
}
.icon-content:before {
  content: "\ee96";
}
.icon-hash:before {
  content: "\e900";
}
.icon-auto_graph:before {
  content: "\ee95";
}
.icon-gender:before {
  content: "\ee92";
}
.icon-female:before {
  content: "\ee93";
}
.icon-male:before {
  content: "\ee94";
}
.icon-windows:before {
  content: "\ebcb";
}
.icon-apple:before {
  content: "\ee90";
}
.icon-error:before {
  content: "\e901";
}
.icon-error_outline:before {
  content: "\e902";
}
.icon-warning:before {
  content: "\e903";
}
.icon-explicit:before {
  content: "\e90a";
}
.icon-hearing:before {
  content: "\e90e";
}
.icon-loop:before {
  content: "\e910";
}
.icon-mic:before {
  content: "\e911";
}
.icon-movie:before {
  content: "\e914";
}
.icon-playlist_add:before {
  content: "\e920";
}
.icon-radio:before {
  content: "\e922";
}
.icon-recent_actors:before {
  content: "\e923";
}
.icon-repeat:before {
  content: "\e924";
}
.icon-stop:before {
  content: "\e92b";
}
.icon-volume_up:before {
  content: "\e934";
}
.icon-web:before {
  content: "\e935";
}
.icon-hd:before {
  content: "\e936";
}
.icon-playlist_add_check:before {
  content: "\e948";
}
.icon-note:before {
  content: "\e952";
}
.icon-4k:before {
  content: "\e955";
}
.icon-business:before {
  content: "\e958";
}
.icon-call:before {
  content: "\e959";
}
.icon-call_received:before {
  content: "\e95e";
}
.icon-chat:before {
  content: "\e960";
}
.icon-comment:before {
  content: "\e962";
}
.icon-contacts:before {
  content: "\e963";
}
.icon-email:before {
  content: "\e966";
}
.icon-message:before {
  content: "\e96d";
}
.icon-chat_bubble:before {
  content: "\e96e";
}
.icon-chat_bubble_outline:before {
  content: "\e96f";
}
.icon-phone:before {
  content: "\e971";
}
.icon-vpn_key:before {
  content: "\e97c";
}
.icon-mail_outline:before {
  content: "\e983";
}
.icon-alternate_email:before {
  content: "\e988";
}
.icon-add:before {
  content: "\e993";
}
.icon-archive:before {
  content: "\e997";
}
.icon-block:before {
  content: "\e999";
}
.icon-clear:before {
  content: "\e99a";
}
.icon-close:before {
  content: "\e99a";
}
.icon-content_copy:before {
  content: "\e99b";
}
.icon-content_paste:before {
  content: "\e99d";
}
.icon-create:before {
  content: "\e99e";
}
.icon-edit:before {
  content: "\e99e";
}
.icon-drafts:before {
  content: "\e99f";
}
.icon-filter_list:before {
  content: "\e9a0";
}
.icon-flag:before {
  content: "\e9a1";
}
.icon-forward:before {
  content: "\e9a2";
}
.icon-link:before {
  content: "\e9a5";
}
.icon-remove:before {
  content: "\e9a7";
}
.icon-reply:before {
  content: "\e9aa";
}
.icon-report:before {
  content: "\e9ac";
}
.icon-save:before {
  content: "\e9ad";
}
.icon-send:before {
  content: "\e9af";
}
.icon-sort:before {
  content: "\e9b0";
}
.icon-undo:before {
  content: "\e9b2";
}
.icon-unarchive:before {
  content: "\e9b5";
}
.icon-weekend:before {
  content: "\e9b7";
}
.icon-low_priority:before {
  content: "\e9b9";
}
.icon-link_off:before {
  content: "\e9bb";
}
.icon-save_alt:before {
  content: "\e9bd";
}
.icon-file_copy:before {
  content: "\e9bf";
}
.icon-how_to_reg:before {
  content: "\e9c0";
}
.icon-dvr:before {
  content: "\e9db";
}
.icon-storage:before {
  content: "\e9f2";
}
.icon-usb:before {
  content: "\e9f3";
}
.icon-attach_file:before {
  content: "\e9fb";
}
.icon-attach_money:before {
  content: "\e9fc";
}
.icon-border_color:before {
  content: "\ea00";
}
.icon-functions:before {
  content: "\ea1f";
}
.icon-insert_drive_file:before {
  content: "\ea22";
}
.icon-insert_invitation:before {
  content: "\ea24";
}
.icon-event:before {
  content: "\ea24";
}
.icon-publish:before {
  content: "\ea27";
}
.icon-vertical_align_top:before {
  content: "\ea2c";
}
.icon-highlight:before {
  content: "\ea31";
}
.icon-title:before {
  content: "\ea36";
}
.icon-table_chart:before {
  content: "\ea37";
}
.icon-score:before {
  content: "\ea3b";
}
.icon-notes:before {
  content: "\ea3e";
}
.icon-attachment:before {
  content: "\ea3f";
}
.icon-cloud:before {
  content: "\ea40";
}
.icon-cloud_download:before {
  content: "\ea43";
}
.icon-cloud_upload:before {
  content: "\ea46";
}
.icon-file_download:before {
  content: "\ea47";
}
.icon-folder:before {
  content: "\ea49";
}
.icon-cast:before {
  content: "\ea4d";
}
.icon-dock:before {
  content: "\ea53";
}
.icon-keyboard:before {
  content: "\ea56";
}
.icon-keyboard_arrow_down:before {
  content: "\ea57";
}
.icon-keyboard_arrow_left:before {
  content: "\ea58";
}
.icon-chevron_left:before {
  content: "\ea58";
}
.icon-keyboard_arrow_right:before {
  content: "\ea59";
}
.icon-chevron_right:before {
  content: "\ea59";
}
.icon-keyboard_return:before {
  content: "\ea5e";
}
.icon-mouse:before {
  content: "\ea65";
}
.icon-router:before {
  content: "\ea69";
}
.icon-scanner:before {
  content: "\ea6a";
}
.icon-security:before {
  content: "\ea6b";
}
.icon-speaker:before {
  content: "\ea6d";
}
.icon-tablet:before {
  content: "\ea6f";
}
.icon-tv:before {
  content: "\ea73";
}
.icon-watch:before {
  content: "\ea74";
}
.icon-adjust:before {
  content: "\ea7b";
}
.icon-camera:before {
  content: "\ea88";
}
.icon-camera_alt:before {
  content: "\ea89";
}
.icon-colorize:before {
  content: "\ea91";
}
.icon-compare:before {
  content: "\ea92";
}
.icon-crop:before {
  content: "\ea96";
}
.icon-details:before {
  content: "\ea9f";
}
.icon-filter:before {
  content: "\eaa9";
}
.icon-flip:before {
  content: "\eabd";
}
.icon-gradient:before {
  content: "\eabe";
}
.icon-healing:before {
  content: "\eac6";
}
.icon-iso:before {
  content: "\eac8";
}
.icon-lens:before {
  content: "\eacb";
}
.icon-looks:before {
  content: "\eacd";
}
.icon-loupe:before {
  content: "\ead3";
}
.icon-nature:before {
  content: "\ead6";
}
.icon-panorama:before {
  content: "\ead9";
}
.icon-panorama_fisheye:before {
  content: "\eada";
}
.icon-picture_as_pdf:before {
  content: "\eadf";
}
.icon-remove_red_eye:before {
  content: "\eae1";
}
.icon-visibility:before {
  content: "\eae1";
}
.icon-style:before {
  content: "\eae7";
}
.icon-timer:before {
  content: "\eaee";
}
.icon-transform:before {
  content: "\eaf1";
}
.icon-tune:before {
  content: "\eaf2";
}
.icon-directions:before {
  content: "\eb0a";
}
.icon-hotel:before {
  content: "\eb12";
}
.icon-layers:before {
  content: "\eb13";
}
.icon-local_drink:before {
  content: "\eb1b";
}
.icon-local_offer:before {
  content: "\eb24";
}
.icon-local_shipping:before {
  content: "\eb2a";
}
.icon-map:before {
  content: "\eb2d";
}
.icon-navigation:before {
  content: "\eb2e";
}
.icon-train:before {
  content: "\eb3e";
}
.icon-tram:before {
  content: "\eb3f";
}
.icon-atm:before {
  content: "\eb41";
}
.icon-category:before {
  content: "\eb42";
}
.icon-360:before {
  content: "\eb45";
}
.icon-money:before {
  content: "\eb4b";
}
.icon-apps:before {
  content: "\eb4c";
}
.icon-arrow_back:before {
  content: "\eb4d";
}
.icon-cancel:before {
  content: "\eb52";
}
.icon-check:before {
  content: "\eb53";
}
.icon-expand_more:before {
  content: "\eb55";
}
.icon-fullscreen:before {
  content: "\eb56";
}
.icon-fullscreen_exit:before {
  content: "\eb57";
}
.icon-menu:before {
  content: "\eb58";
}
.icon-keyboard_control:before {
  content: "\eb59";
}
.icon-more_horiz:before {
  content: "\eb59";
}
.icon-refresh:before {
  content: "\eb5b";
}
.icon-arrow_upward:before {
  content: "\eb5e";
}
.icon-arrow_downward:before {
  content: "\eb61";
}
.icon-first_page:before {
  content: "\eb62";
}
.icon-last_page:before {
  content: "\eb63";
}
.icon-arrow_left:before {
  content: "\eb64";
}
.icon-arrow_right:before {
  content: "\eb65";
}
.icon-adb:before {
  content: "\eb68";
}
.icon-event_busy:before {
  content: "\eb6d";
}
.icon-more:before {
  content: "\eb71";
}
.icon-power:before {
  content: "\eb8e";
}
.icon-wc:before {
  content: "\eb8f";
}
.icon-group:before {
  content: "\eba1";
}
.icon-notifications:before {
  content: "\eba5";
}
.icon-pages:before {
  content: "\ebaa";
}
.icon-person:before {
  content: "\ebad";
}
.icon-person_add:before {
  content: "\ebae";
}
.icon-person_outline:before {
  content: "\ebaf";
}
.icon-public:before {
  content: "\ebb1";
}
.icon-share:before {
  content: "\ebb3";
}
.icon-check_box:before {
  content: "\ebbc";
}
.icon-check_box_outline_blank:before {
  content: "\ebbd";
}
.icon-star:before {
  content: "\ebbf";
}
.icon-star_outline:before {
  content: "\ebc1";
}
.icon-accessibility:before {
  content: "\ebc3";
}
.icon-account_box:before {
  content: "\ebc6";
}
.icon-account_circle:before {
  content: "\ebc7";
}
.icon-android:before {
  content: "\ee91";
}
.icon-announcement:before {
  content: "\ebcc";
}
.icon-assignment:before {
  content: "\ebce";
}
.icon-book:before {
  content: "\ebd5";
}
.icon-build:before {
  content: "\ebd9";
}
.icon-cached:before {
  content: "\ebda";
}
.icon-check_circle:before {
  content: "\ebdc";
}
.icon-code:before {
  content: "\ebde";
}
.icon-dashboard:before {
  content: "\ebe0";
}
.icon-delete:before {
  content: "\ebe1";
}
.icon-description:before {
  content: "\ebe2";
}
.icon-dns:before {
  content: "\ebe3";
}
.icon-done:before {
  content: "\ebe4";
}
.icon-explore:before {
  content: "\ebe7";
}
.icon-extension:before {
  content: "\ebe8";
}
.icon-face:before {
  content: "\ebe9";
}
.icon-favorite:before {
  content: "\ebea";
}
.icon-help:before {
  content: "\ebf1";
}
.icon-highlight_remove:before {
  content: "\ebf2";
}
.icon-highlight_off:before {
  content: "\ebf2";
}
.icon-history:before {
  content: "\ebf3";
}
.icon-home:before {
  content: "\ebf4";
}
.icon-https:before {
  content: "\ebf7";
}
.icon-info:before {
  content: "\ebf8";
}
.icon-info_outline:before {
  content: "\ebf9";
}
.icon-input:before {
  content: "\ebfa";
}
.icon-label:before {
  content: "\ebfc";
}
.icon-language:before {
  content: "\ebfe";
}
.icon-launch:before {
  content: "\ebff";
}
.icon-list:before {
  content: "\ec00";
}
.icon-lock_open:before {
  content: "\ec01";
}
.icon-lock_outline:before {
  content: "\ec02";
}
.icon-receipt:before {
  content: "\ec13";
}
.icon-search:before {
  content: "\ec15";
}
.icon-settings:before {
  content: "\ec16";
}
.icon-settings_backup_restore:before {
  content: "\ec18";
}
.icon-settings_ethernet:before {
  content: "\ec1c";
}
.icon-settings_input_component:before {
  content: "\ec1e";
}
.icon-settings_input_composite:before {
  content: "\ec1e";
}
.icon-shop:before {
  content: "\ec26";
}
.icon-shopping_basket:before {
  content: "\ec28";
}
.icon-speaker_notes:before {
  content: "\ec29";
}
.icon-stars:before {
  content: "\ec2b";
}
.icon-subject:before {
  content: "\ec2c";
}
.icon-supervisor_account:before {
  content: "\ec2d";
}
.icon-tab:before {
  content: "\ec32";
}
.icon-toc:before {
  content: "\ec37";
}
.icon-today:before {
  content: "\ec38";
}
.icon-translate:before {
  content: "\ec3b";
}
.icon-view_column:before {
  content: "\ec43";
}
.icon-view_quilt:before {
  content: "\ec48";
}
.icon-visibility_off:before {
  content: "\ec4b";
}
.icon-work:before {
  content: "\ec4e";
}
.icon-eject:before {
  content: "\ec50";
}
.icon-help_outline:before {
  content: "\ec52";
}
.icon-zoom_in:before {
  content: "\ec54";
}
.icon-zoom_out:before {
  content: "\ec55";
}
.icon-http:before {
  content: "\ec56";
}
.icon-gif:before {
  content: "\ec5b";
}
.icon-indeterminate_check_box:before {
  content: "\ec5c";
}
.icon-copyright:before {
  content: "\ec5f";
}
.icon-accessible:before {
  content: "\ec66";
}
.icon-date_range:before {
  content: "\ec68";
}
.icon-opacity:before {
  content: "\ec6e";
}
.icon-rowing:before {
  content: "\ec73";
}
.icon-update:before {
  content: "\ec75";
}
.icon-check_circle_outline:before {
  content: "\ec7f";
}
.icon-minimize:before {
  content: "\ec83";
}
.icon-calendar_today:before {
  content: "\ec87";
}
.icon-compress:before {
  content: "\ec9d";
}
.icon-expand:before {
  content: "\ec9f";
}
.icon-10k:before {
  content: "\eca1";
}
.icon-1k:before {
  content: "\ecac";
}
.icon-2k:before {
  content: "\ecb3";
}
.icon-3k:before {
  content: "\ecb6";
}
.icon-4mp:before {
  content: "\ecba";
}
.icon-5k:before {
  content: "\ecbb";
}
.icon-5mp:before {
  content: "\ecbd";
}
.icon-6k:before {
  content: "\ecbe";
}
.icon-7k:before {
  content: "\ecc1";
}
.icon-8k:before {
  content: "\ecc4";
}
.icon-9k:before {
  content: "\ecc7";
}
.icon-account_tree:before {
  content: "\ecca";
}
.icon-approval:before {
  content: "\eccf";
}
.icon-cases:before {
  content: "\ecd4";
}
.icon-dangerous:before {
  content: "\ecd8";
}
.icon-duo:before {
  content: "\ecde";
}
.icon-rtt:before {
  content: "\ece1";
}
.icon-hail:before {
  content: "\ece3";
}
.icon-logout:before {
  content: "\ece8";
}
.icon-margin:before {
  content: "\ece9";
}
.icon-mp:before {
  content: "\ecec";
}
.icon-padding:before {
  content: "\ecee";
}
.icon-recommend:before {
  content: "\ecf7";
}
.icon-sd:before {
  content: "\ecfe";
}
.icon-shield:before {
  content: "\ecff";
}
.icon-stream:before {
  content: "\ed03";
}
.icon-swipe:before {
  content: "\ed04";
}
.icon-tag:before {
  content: "\ed06";
}
.icon-file_present:before {
  content: "\ed16";
}
.icon-euro:before {
  content: "\ed1d";
}
.icon-height:before {
  content: "\ed1e";
}
.icon-people_alt:before {
  content: "\ed29";
}
.icon-sports:before {
  content: "\ed37";
}
.icon-eco:before {
  content: "\ed3c";
}
.icon-deck:before {
  content: "\ed43";
}
.icon-house:before {
  content: "\ed45";
}
.icon-park:before {
  content: "\ed58";
}
.icon-badge:before {
  content: "\ed5c";
}
.icon-pool:before {
  content: "\ed70";
}
.icon-spa:before {
  content: "\ed74";
}
.icon-5g:before {
  content: "\ed78";
}
.icon-analytics:before {
  content: "\ed82";
}
.icon-anchor:before {
  content: "\ed83";
}
.icon-animation:before {
  content: "\ed84";
}
.icon-api:before {
  content: "\ed85";
}
.icon-architecture:before {
  content: "\ed89";
}
.icon-article:before {
  content: "\ed8c";
}
.icon-calculate:before {
  content: "\eda1";
}
.icon-circle:before {
  content: "\eda7";
}
.icon-east:before {
  content: "\edb8";
}
.icon-escalator:before {
  content: "\edc2";
}
.icon-escalator_warning:before {
  content: "\edc3";
}
.icon-fact_check:before {
  content: "\edc5";
}
.icon-filter_alt:before {
  content: "\edc8";
}
.icon-flaky:before {
  content: "\edca";
}
.icon-lock_clock:before {
  content: "\ede6";
}
.icon-login:before {
  content: "\ede7";
}
.icon-nat:before {
  content: "\edfc";
}
.icon-pending:before {
  content: "\ee18";
}
.icon-pending_actions:before {
  content: "\ee19";
}
.icon-person_add_alt:before {
  content: "\ee1a";
}
.icon-person_add_alt_1:before {
  content: "\ee1b";
}
.icon-push_pin:before {
  content: "\ee2a";
}
.icon-request_quote:before {
  content: "\ee30";
}
.icon-rule:before {
  content: "\ee34";
}
.icon-source:before {
  content: "\ee44";
}
.icon-subscript:before {
  content: "\ee4f";
}
.icon-support:before {
  content: "\ee52";
}
.icon-tapas:before {
  content: "\ee58";
}
.icon-tty:before {
  content: "\ee5c";
}
.icon-upgrade:before {
  content: "\ee5e";
}
.icon-wash:before {
  content: "\ee62";
}
.icon-west:before {
  content: "\ee64";
}
.icon-wysiwyg:before {
  content: "\ee6a";
}
.icon-facebook:before {
  content: "\ee7c";
}
.icon-groups:before {
  content: "\ee7d";
}
